<template>
    <div class="downloadapp">
        <Tabs></Tabs>
        <div class="banner">
            <div class="w1400">
                <div class="l">
                    <h2 style="font-style: italic;">
                        <i>APP下载</i>
                    </h2>
                    <p>
                        扫码一键下载，随时随地学习、刷题，
                        考试快人一步！！！
                    </p>
                </div>
                <div class="r">
                    <img src="../../assets/img/page_background.png" alt />
                </div>
            </div>
        </div>
        <div class="list">
            <div class="box" @click="open(`/help/downlod.html`)">
                <div class="l">
                    <img src="https://res-qd.liyouedu.cn/resources/client/erweima/xiazai.png"
                        alt />
                </div>
                <div class="r">
                    <div class="t">
                        立优课堂
                        <a class="but">
                            了解更多
                        </a>
                    </div>
                    <p>学员专属移动课堂，随时随地备考学习</p>
                    <p>手机电脑同步听课，海量免费公开课随时学习</p>
                    <p>随机/章节精练、历年直题、模拟考试、海量题库实时检测学习成果</p>
                </div>
            </div>
            <div class="box" @click="open(`/help/jdownlod.html`)">
                <div class="l">
                    <img src="https://res-qd.liyouedu.cn/resources/dl/jianzao/j.png" alt />
                </div>
                <div class="r">
                    <div class="t">
                        建造师刷题宝
                        <a class="but">
                            了解更多
                        </a>
                    </div>
                    <p>免费海量试题查漏补缺，高频考点帮您快速提分，精编答题解析助你掌握考试技巧</p>
                    <p>多场模拟考试，巩固知识的同时强化心理素质，让你轻松面对每一场考试!</p>
                </div>
            </div>
            <div class="box" @click="open(`/help/ydownlod.html`)">
                <div class="l">
                    <img src="https://res-qd.liyouedu.cn/resources/dl/yaoshi/y.png" alt />
                </div>
                <div class="r">
                    <div class="t">
                        药师刷题宝
                        <a class="but">
                            了解更多
                        </a>
                    </div>
                    <p>章节练习：根据教材章节精选考点进行练习</p>
                    <p>历年真题：历年考试真题免费做题，系统自动评分</p>
                    <p>为考执业药师资格的学员提供免费、海量的在线题库，是考生参加执业药师考试的掌中宝</p>
                </div>
            </div>
            <div class="box" @click="open(`/help/adownlod.html`)">
                <div class="l">
                    <img src="https://res-qd.liyouedu.cn/resources/dl/anquan/an.png" alt />
                </div>
                <div class="r">
                    <div class="t">
                        安全工程刷题宝
                        <a class="but">
                            了解更多
                        </a>
                    </div>
                    <p>章节考点练习，考点随时看</p>
                    <p>精选往年考试真题，助考生掌握备考规律</p>
                    <p>高频考点深入解析，把握精髓，不忽略一个细节</p>
                </div>
            </div>
        </div>
        <Foot></Foot>
    </div>
</template>

<script>
export default {
    name: 'Downloadapp',
    data() {
        return {

        }
    },
    methods: {
        open(e) {
            console.log(e)
            window.open(this.url.baseUrl + e)
        }
    },
    created() {

    }
}
</script>

<style lang="scss" scoped>
.list {
    width: 1400px;
    margin: 0 auto;
    line-height: 1;
    padding: 60px 0 140px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .box:hover {
        transform: scale(1.03);
    }

    .box {
        padding: 30px;
        background: #ffffff;
        box-shadow: 0px 4px 8px 0px rgba(0, 27, 89, 0.05);
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        cursor: pointer;
        transition: all 0.3s;

        .l {
            width: 168px;
            height: 168px;
            margin-right: 35px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .r {
            .t {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 23px;
                font-size: 24px;
                font-family: Microsoft YaqiHei;
                font-weight: bold;
                color: #1c1c1c;

                .but {
                    padding: 8px 18px;
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #ffffff;
                    background: #3e7eff;
                    border-radius: 6px;
                }
            }

            p {
                width: 399px;
                padding-left: 15px;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 23px;
                margin-bottom: 13px;
                position: relative;
            }

            p::after {
                content: "";
                display: block;
                width: 10px;
                height: 10px;
                background: url(../../assets/img/langou.png) no-repeat center;
                background-size: 100%;
                position: absolute;
                top: 5px;
                left: 0;
            }
        }
    }
}

.banner {
    background: #4379f6;

    .w1400 {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .l {
        text-align: left;

        h2 {
            font-size: 50px;
            font-family: Microsoft YaqiHei;
            color: #ffffff;
            font-weight: bold;
            text-shadow: 0px 4px 8px rgba(0, 31, 102, 0.1);
            margin-bottom: 19px;
        }

        p {
            width: 332px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 27px;
        }
    }
}

.w1400 {
    width: 1400px;
    margin: 0 auto;
}
</style>